import '../asset/components/Confirmation.css';
import Modal from "react-modal";

function Confirmation({state, onCloseModal}) {
    const {parentObjects, newObject, objectsToDelete, modalIsOpen, onConfirmation} = state;

    return <Modal
        style={{
            content: {
                top: '20%',
                left: '50%',
                transform: 'translate(-50%, -20%)',
                width: '50%',
                height: '55%',
                maxHeight: '325px'
            }
        }}
        isOpen={modalIsOpen} onRequestClose={onCloseModal}>
        {
            objectsToDelete.length > 0 &&
            <div className="confirmation">
                <div>
                    <p>El producto <span className="confirmation-product-label">{newObject.label}</span> es incompatible
                        con:</p>
                </div>
                <div>
                    <ul>
                        {
                            objectsToDelete.map(object => <li><span
                                className="confirmation-product-label">{object.label}</span></li>)
                        }
                    </ul>
                </div>
                <div>
                    <p>¿Desea eliminarlos para agregar el producto seleccionado?</p>
                </div>
                <div>
                    <button className="confirmation-button confirmation-yes-button" onClick={onConfirmation}>Si,
                        eliminarlos y agregar el nuevo producto
                    </button>
                    <button className="confirmation-button confirmation-no-button" onClick={onCloseModal}>No, dejar
                        los productos seleccionados
                    </button>
                </div>
            </div>
        }
        {
            parentObjects.length > 0 &&
            <div className="confirmation">
                <div>
                    <p>El producto <span className="confirmation-product-label">{newObject.label}</span> solo es
                        incompatible con
                        <ul>
                        {
                            parentObjects.length === 1 ?
                                <li><span className="confirmation-product-label">{parentObjects[0].label}</span></li>
                                :
                                parentObjects.map(
                                    parentObject => <li><span key={parentObject.objeto360} className="confirmation-product-label">{parentObject.label}</span></li>
                                )
                        }
                        </ul>
                    </p>
                </div>
                <div>
                    <p>¿Qué desea hacer?</p>
                </div>
                <div>
                    {
                        parentObjects.length === 1 ?
                            <button className="confirmation-button confirmation-yes-button" onClick={e => onConfirmation(parentObjects[0])}>Agregar ambos productos</button>
                            :
                            parentObjects.map(
                                parentObject => <button key={parentObject.objeto360} className="confirmation-button confirmation-yes-button" onClick={e => onConfirmation(parentObject)}>Agregar {parentObject.label}</button>
                            )
                    }
                    <button className="confirmation-button confirmation-no-button" onClick={onCloseModal}>Cancelar, no modificar nada
                    </button>
                </div>
            </div>
        }
    </Modal>;
}

export default Confirmation;
