/*
export const ASSETS_URL = 'https://simuladorcoronaintegrado.mudi.com.co/app/ASSETS';
export const APP_URL = 'http://localhost:8000';
export const API_URL = 'http://localhost:8000/api';
*/

export const ASSETS_URL = 'https://simuladorcoronaintegrado.mudi.com.co/app/ASSETS';
export const APP_URL = 'https://simuladorcoronaintegrado.mudi.com.co';
export const API_URL = 'https://simuladorcoronaintegrado.mudi.com.co/api';

export const IP_URL = 'https://api.ipstack.com/check?access_key=ebbe6ddd35beaba94f22cd207a38cbbf&fields=country_name,region_name,city';
