import {useState, useEffect, Fragment} from "react";

function FormWidthHeight({category, object}) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [units, setUnits] = useState(0);
    const [boxes, setBoxes] = useState(0);
    const [adhesive, setAdhesive] = useState(0);
    const [grout, setGrout] = useState(0);

    useEffect(
        () => {
            const area = width * height;
            const newBoxes = Math.ceil(area / object.m_caja);
            const newUnits = newBoxes * object.u_caja;

            let objectArea = object.ancho * object.largo;

            let groutPerformance = 0.24;
            if( objectArea <= 100){
                groutPerformance = 0.97;
            }
            else if( objectArea > 100 && objectArea <= 400){
                groutPerformance = 0.49;
            }
            else if( objectArea > 400 && objectArea <= 900){
                groutPerformance = 0.32;
            }
            const newAdhesive = (area * groutPerformance).toFixed(2);

            let adhesivePerformance = 6;
            if( objectArea <= 100){
                adhesivePerformance = 2;
            }
            else if( objectArea > 100 && objectArea <= 400){
                adhesivePerformance = 3;
            }
            else if( objectArea > 400 && objectArea <= 900){
                adhesivePerformance = 4;
            }
            const newGrout = (area * adhesivePerformance).toFixed(2);

            setUnits(newUnits);
            setBoxes(newBoxes);
            setAdhesive(newAdhesive);
            setGrout(newGrout);
        },
        [width, height]
    )

    return <Fragment key={object.id}>
        <section className="form-category-content">
            <p className="form-product-label">Producto: {object.label} Ref:
                {object.sku.split('_')[0]}</p>
            {
                category.ancho ?
                    <Fragment>
                        <label className="form-label">Ancho (mt)</label>
                        <input className="form-input-short" type="number" min="0" required
                               value={width} onChange={e => setWidth(e.target.value)}/>
                    </Fragment>
                    : ''
            }
            {
                category.largo ?
                    <Fragment>
                        <label className="form-label">Largo (mt)</label>
                        <input className="form-input-short" type="number" min="0" required
                               value={height} onChange={e => setHeight(e.target.value)}/>
                    </Fragment>
                    : ''
            }
        </section>
        {
            (category.ancho || category.largo) ?
                <section className="form-result">
                    <p>Requiere:</p>
                    <ul>
                        <li>{units} Unidades</li>
                        <li>{boxes} Cajas</li>
                        <li>Boquilla: {adhesive} Kg</li>
                        <li>Pegante: {grout} Kg</li>
                    </ul>
                </section>
                : ''
        }
    </Fragment>;
}

export default FormWidthHeight;
