import '../asset/components/Selector.css';
import {useContext, useEffect, useState} from "react";
import AppContext from "../context/AppContext";

function SceneSelector({initialState, setSceneStyle}){
    const [selectedScene, setSelectedScene] = useState(initialState.scene);
    const [scenes, setScenes] = useState([]);

    const [selectedStyle, setSelectedStyle] = useState(initialState.style);
    const [styles, setStyles] = useState([]);
    const {repository} = useContext(AppContext);

    useEffect(() => {
        repository.getScenes().then(
            (scenes) => {
                setScenes(scenes);
            }
        );
        repository.getStyles().then(
            (styles) => {
                setStyles(styles);
            }
        );
    }, []);

    return <div className="selector"    >
        <select onChange={e => setSelectedScene(e.target.value)}>
            <option selected={true} disabled={true}>Selecciona un ambiente</option>
            {
                scenes.map(scene => <option key={scene.id} value={scene.id}>{scene.nombre}</option>)
            }
        </select>
        <select onChange={e => setSelectedStyle(e.target.value)}>
            <option selected={true} disabled={true}>Selecciona un estilo</option>
            {
                styles.map(style => <option key={style.id} value={style.id}>{style.nombre}</option>)
            }
        </select>
        <button onClick={e => setSceneStyle(selectedScene, selectedStyle)}>Simular</button>
    </div>
}

export default SceneSelector;
