import {useEffect, useState} from 'react';

import useKrpanoView from "../hooks/useKrpanoView";

const KrpanoView = ({objectToAdd, objectToRemove, state}) => {
    const [pendingObjectsToAdd, setPendingObjectsToAdd] = useState([]);

    const {
        isLoaded,
        containerRef,
        callKrpano,
    } = useKrpanoView(state);

    const updateScene = () => {
        if (state.scene && state.style && isLoaded) {
            callKrpano(`set(escena , ${state.scene});`);
            callKrpano(`set(estilo , ${state.style});`);
            callKrpano(`loadscene(scene_${state.scene}, null, MERGE, BLEND(1));`);

            pendingObjectsToAdd.forEach(
                objectToAdd => {
                    callKrpano(`render360(${objectToAdd.sku}, ${objectToAdd.z}, ${objectToAdd.f}, ${objectToAdd.r}, ${objectToAdd.b}, ${objectToAdd.l}, ${objectToAdd.d}, ${objectToAdd.u});`);
                }
            );
            setPendingObjectsToAdd([]);
        }
    }

    useEffect(updateScene, [isLoaded])

    useEffect(updateScene, [state]);
    useEffect
    (() => {
        if (objectToAdd != null) {
            if (isLoaded) {
                callKrpano(`render360(${objectToAdd.sku}, ${objectToAdd.z}, ${objectToAdd.f}, ${objectToAdd.r}, ${objectToAdd.b}, ${objectToAdd.l}, ${objectToAdd.d}, ${objectToAdd.u});`);
            } else {
                const newPendingObjectsToAdd = [...pendingObjectsToAdd, objectToAdd];
                setPendingObjectsToAdd(newPendingObjectsToAdd);
            }
        }
    }, [objectToAdd]);

    useEffect
    (() => {
        if (objectToRemove != null) {
            callKrpano(`remove360(${objectToRemove.sku});`);
        }
    }, [objectToRemove]);
    return <div style={{width: '100%', height: '100%'}}>
        <div ref={containerRef}/>
    </div>;
}

export default KrpanoView;
