import SessionRepository from "./SessionRepository";
import {API_URL, APP_URL, IP_URL} from '../services/Configuration';

class SessionManager {
    userID;
    startDate;
    elapsedTime;
    sessionID;
    scene;
    style;
    customer;
    tour;
    repository;

    constructor({scene, style, customer, tour}) {
        this.startDate = new Date();
        this.elapsedTime = 0;
        this.repository = new SessionRepository(API_URL, APP_URL);

        this.scene = scene;
        this.style = style;
        this.customer = customer;
        this.tour = tour;

        let cookieUserID = this.getCookie("simuladorcorona_user");
        if (cookieUserID === "") {
            cookieUserID = Math.floor(Math.random() * Date.now());
            document.cookie = "simuladorcorona_user=" + cookieUserID + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        }
        this.userID = cookieUserID;
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    restartTime() {
        console.log('restartTime');
        this.startDate = new Date();
    }

    addSession() {
        const repository = this.repository;
        const os = this.getOS();
        return this.getIPInfo().then(
            (json) => {
                const country = json.country_name;
                const region = json.region_name;
                const city = json.city;
                return repository.addSession(
                    {
                        id_escenas: this.scene,
                        origin: this.tour,
                        id_estilos: this.style,
                        userid: this.userID,
                        os: os,
                        country: country,
                        region: region,
                        city: city
                    }
                )
            }
        )
            .then((data) => {
                this.sessionID = data.id || data.data.id;
            });
    }

    updateTime() {
        const endDate = new Date();
        const spentTime = endDate.getTime() - this.startDate.getTime();
        this.elapsedTime += spentTime;

        const seg = Math.floor(this.elapsedTime / 1000);
        if (0 < seg) {
            const obj = this;
            let p = Promise.resolve();
            if (!this.sessionID) {
                p = this.addSession();
            }
            p.then(
                () => {
                    obj.repository.updateSessionTime({id: obj.sessionID, tiempo: obj.toHHMMSS(seg), segundos: seg});
                }
            )
        }
    }

    toHHMMSS(sec_num) {
        //var sec_num = parseInt(this, 10); // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    }

    getIPInfo() {
        return fetch(IP_URL);
    }

    getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    addSKU(object){
        this.repository.addSKU({
            id_sesiones: this.sessionID,
            sku: object.sku,
            objetos360_sku: object.sku,
            categorias_dash: object.id_categorias_dash,
            subcategorias_dash: object.id_subcategorias_dash,
        });
    }

    addDist({emailSeller, emailCustomer, shop}) {
        const sessionID = this.sessionID;
        const repository = this.repository;
        this.getIPInfo().then(
            (json) => {
                const country = json.country_name;
                const region = json.region_name;
                const city = json.city;

                return repository.addDist({
                    id_sesiones: sessionID,
                    asesor: emailSeller,
                    cliente: emailCustomer,
                    punto_venta: shop,
                    country: country,
                    region: region,
                    city: city
                });
            }
        );
    }
}

export default SessionManager;
