import React, {useContext, useState, useEffect, useRef, useCallback} from 'react';
import AppContext from "../context/AppContext";
import '../asset/components/Menu.css';
import '../asset/components/Options.css';
import '../asset/components/Option.css';

const Menu = (props) => {
    const loader = useRef(null);
    const {ASSETS_URL, repository} = useContext(AppContext);
    const [hideLoader, setHideLoader] = useState(true);
    const [options, setOptions] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit] = useState(6);
    const [parentOption, setParentOption] = useState(null);
    const [currentOption, setCurrentOption] = useState(null);
    const {onOpenMenu, onCloseMenu} = props;
    const {scene, style, customer} = props.state;
    const {onLeafOptionClick} = props;
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setOffset((prev) => prev + limit);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "0px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    const onClickOption = (newOption) => {
        if (newOption.next !== 0) {
            setParentOption(currentOption);
            setCurrentOption(newOption);
            setOptions([]);
            setOffset(0);
        } else {
            onLeafOptionClick(newOption);
        }
    }

    const onClickTitleBtn = (e) => {
        if (e.target.classList[0] === 'close') {
            setIsMenuOpen(false);
            onCloseMenu();
        } else {
            if (parentOption !== null) {
                setCurrentOption(parentOption);
                setOptions([]);
                setOffset(0);
                repository.getOptionById(scene, style, customer, parentOption.parent)
                    .then(
                        (newParent) => {
                            setParentOption(newParent);
                        }
                    )
                setParentOption(null);
            }
        }
    }

    useEffect(
        () => {
            if (scene && style && customer) {
                setCurrentOption(null);
                setOptions([]);
                setOffset(0);
                repository.getOptions(scene, style, customer, -1, 0, 1)
                    .then(
                        (newOptions) => {
                            if (newOptions.length > 0) {
                                setOffset(0);
                                setCurrentOption(newOptions[0]);
                            }
                        }
                    );
            }
        },
        [scene, style, customer]
    )
    useEffect(
        () => {
            if (currentOption) {
                repository.getOptions(scene, style, customer, currentOption.id, offset, limit)
                    .then(
                        (newOptions) => {
                            setHideLoader(newOptions.length === 0);
                            if (newOptions.length > 0) {
                                if (newOptions[0].parent === currentOption.id) {
                                    setOptions(prev => [...prev, ...newOptions]);
                                }
                            }
                        }
                    );
            }
        },
        [currentOption, offset]
    )

    const openMenu = () => {
        onOpenMenu();
        setIsMenuOpen(true);
    }

    return <div className="menu">
        <button className={`menu-button ${isMenuOpen ? 'menu-button-hidden' : ''}`} onClick={openMenu}>☰</button>
        {
            currentOption && isMenuOpen &&
            <div className="title">
                <div id="titleBtn" className={currentOption.parent !== -1 ? 'back' : 'close'} onClick={(e) => {
                    onClickTitleBtn(e)
                }}/>
                <span className="label">{currentOption.label}</span>
            </div>

        }
        {
            isMenuOpen &&
            <div className="options-container">
                <div className="options">
                    {
                        options.map(option => (
                            <div key={option.id} className={"option " + option.class} onClick={() => {
                                onClickOption(option)
                            }}>
                                <img alt={option.label} className="image" src={ASSETS_URL + '/' + option.img}/>
                                <span className="label">{option.label}</span>
                                {option.next ? <div className="next"></div> : ""}
                            </div>
                        ))
                    }
                    <div className={"option small loader" + (hideLoader ? " hidden" : "")} ref={loader}/>
                </div>
            </div>
        }
    </div>;
}

export default Menu;
