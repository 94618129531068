import OptionRepository from "../services/OptionRepository";
import {API_URL, APP_URL, ASSETS_URL} from '../services/Configuration';
import SessionManager from "../services/SessionManager";

const useAppState = ({scene, style, customer, tour}) => {
    const repository = new OptionRepository(API_URL, APP_URL);
    const sessionManager = new SessionManager({scene, style, customer, tour});
    return {
        APP_URL,
        API_URL,
        ASSETS_URL,
        state: {scene, style, customer, tour},
        repository,
        sessionManager
    }
}

export default useAppState;
