import '../asset/components/Toolbar.css';
import Modal from "react-modal";
import Form from "./Form";
import {Fragment, useContext, useState} from "react";
import AsyncSelect from 'react-select/async';
import AppContext from "../context/AppContext";

function Toolbar({scene, style, customer, onSelectSKU, objectsInScene}) {
    const [selectedValue, setSelectedValue] = useState(null);
    const {repository} = useContext(AppContext);
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const loadSKUs = (query) => repository.getOptionByLabel(scene, style, customer, query);

    return <Fragment>
        <div className="toolbar-top">
            <button className="button button-home" onClick={() => window.history.back()}></button>
            <AsyncSelect placeholder="Buscar SKU..." isClearable={true} cacheOptions defaultOptions
                         loadOptions={loadSKUs} onChange={e => e && onSelectSKU(e)}/>
        </div>
        <div className="toolbar-bottom">
            <button className="button button-calculate" onClick={openModal}>Calcula tu proyecto</button>
            <Modal style={{content: {top: '20%', left: '50%', transform: 'translate(-50%, -20%)', width: '50%'}}}
                   isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className="form-container">
                    <Form scene={scene} objectsInScene={objectsInScene}></Form>
                </div>
            </Modal>
        </div>
        ;
    </Fragment>
}

export default Toolbar;
