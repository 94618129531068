import '../asset/components/Note.css';

function FormNote() {
    return <div className="note">
        <p className="note-title">Nota:</p>
        <ul className="note-list">
            <li>Se agregará el 10% adicional recomendado para cubrir la superficie total.</li>
            <li>Recuerda que no tendrás un valor exacto. Nuestra calculadora arrojará un resultado con un 10% extra para una cobertura total.</li>
            <li>Al instalar tu revestimientos con Pegacor®, tienes la adhesión garantizada DE POR VIDA.</li>
        </ul>
    </div>
}

export default FormNote;
