class OptionRepository {

    APP_URL;
    API_URL;
    cache = {};

    constructor(api_url, app_url) {
        this.API_URL = api_url;
        this.APP_URL = app_url;
    }

    getOptions(scene, style, customer, parent, offset, limit) {
        if (this.cache.hasOwnProperty(`${scene}-${style}-${customer}-${parent}-${offset}-${limit}`)) {
            return Promise.resolve().then(() => this.cache[`${scene}-${style}-${customer}-${parent}-${offset}-${limit}`]);
        } else {
            return fetch(`${this.API_URL}/menus?scene=${scene}&style=${style}&customer=${customer}&parent=${parent}&offset=${offset}&limit=${limit}`)
                .then(response => response.json())
                .then(object => {
                    this.cache[`${scene}-${style}-${customer}-${parent}-${offset}-${limit}`] = object.data ?? []
                    return this.cache[`${scene}-${style}-${customer}-${parent}-${offset}-${limit}`];
                });
        }
    }

    getOptionById(scene, style, customer, id) {
        return id !== -1 ? fetch(`${this.API_URL}/menus?scene=${scene}&style=${style}&customer=${customer}&menuid=${id}`)
                .then(response => response.json())
                .then(object => (object.data ?? [null])[0])
            : Promise.resolve(null);
    }

    getOptionByLabel(scene, style, customer, label) {
        return fetch(`${this.API_URL}/menus?scene=${scene}&style=${style}&customer=${customer}&label=${label}`)
            .then(response => response.json())
            .then(object => object.data ?? []);
    }

    getOptionByObjectIds(scene, style, customer, objectIds) {
        return fetch(`${this.API_URL}/menus?scene=${scene}&style=${style}&customer=${customer}&objects[]=${objectIds.join('&objects[]=')}`)
            .then(response => response.json())
            .then(object => (object.data ?? []));
    }

    getInitialObjects(scene, style, customer) {
        return fetch(`${this.API_URL}/initialobjects360?scene=${scene}&style=${style}&customer=${customer}`)
            .then(response => response.json())
            .then(object => object.data ?? []);
    }

    getCategories(scene) {
        return fetch(`${this.API_URL}/categories?scene=${scene}`)
            .then(response => response.json())
            .then(object => object.data ?? []);
    }

    getShops(query) {
        return fetch(`${this.API_URL}/shops?query=${query}`)
            .then(response => response.json())
            .then(object => (object.data ?? []).map(shop => {
                    return {value: shop.nombre, label: shop.nombre};
                }
            ));
    }

    getScenes() {
        return fetch(`${this.API_URL}/scenes`)
            .then(response => response.json())
            .then(object => object.data ?? []);
    }

    getStyles() {
        return fetch(`${this.API_URL}/styles`)
            .then(response => response.json())
            .then(object => object.data ?? []);
    }

    sendEmail(emailSeller, emailCustomer, shop, body) {
        const data = {
            email1: emailCustomer,
            email2: emailSeller,
            pventa: shop,
            body: body
        };

        return fetch(`${this.APP_URL}/sendMail`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
    }
}

export default OptionRepository;

