import '../asset/components/Corona.css';
import AppContext from '../context/AppContext';
import KrpanoView from "./KrpanoView";
import Menu from "./Menu";
import {useEffect, useState, useCallback, useContext, useRef} from 'react';
import Toolbar from "./Toolbar";
import Confirmation from "./Confirmation";
import SceneSelector from "./SceneSelector";

function Corona() {

    const initialState = useContext(AppContext);
    const sessionManager = initialState.sessionManager;
    const [state, setState] = useState(initialState.state);
    const [objectsInScene, setObjectsInScene] = useState([]);
    const [objectToAdd, setObjectToAdd] = useState(null);
    const [objectToRemove, setObjectToRemove] = useState(null);
    const [confirmation, setConfirmation] = useState({
        parentObjects: [],
        newObject: {},
        objectsToDelete: [],
        modalIsOpen: false,
        onConfirmation: () => {
        }
    });

    const onMessageReceivedFromIframe = useCallback(
        event => {
            console.log(event);
            if (event.data === 'add-products-click') {
                if (event.origin.includes('corona.co') || event.origin.includes('stag-co.corona.co')) {
                    const products = objectsInScene.map(objectInScene => {
                        return {
                            sku: objectInScene.sku.substr(0, 9),
                            quantity: 1
                        }
                    })
                    console.log(products);
                    window.parent.postMessage(JSON.stringify(products), "*");
                }
            }
        },
        [objectsInScene]
    );

    useEffect(() => {
        window.addEventListener("message", onMessageReceivedFromIframe);
        return () => window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe]);

    useEffect(() => {
        if (state.scene && state.style && state.customer) {
            const {repository} = initialState;
            repository.getInitialObjects(state.scene, state.style, state.customer).then(
                (objects) => {
                    objects.forEach(object => {
                        setObjectToAdd({...object});
                    });
                    setObjectsInScene(objects);
                }
            )
        }
    }, [state]);

    useEffect(
        () => {
            window.addEventListener('focus', sessionManager.restartTime.bind(sessionManager));
            window.addEventListener('blur', sessionManager.updateTime.bind(sessionManager));
            window.addEventListener('beforeunload', sessionManager.updateTime.bind(sessionManager));
        },
        []
    )

    const onCloseConfirmation = () => setConfirmation({
        newObject: {},
        parentObjects: [],
        objectsToDelete: [],
        modalIsOpen: false,
    });

    const onLeafOptionClick = (thatObject) => {
        const thisObject = objectsInScene.find(object => object.objeto360 === thatObject.objeto360);
        if (thisObject) {
            updateScene([], [thatObject]);
        } else {
            processObject(thatObject, {objectsToAdd: {}, objectsToRemove: {}})
                .then(
                    ({objectsToAdd, objectsToRemove}) => {
                        updateScene(Object.values(objectsToAdd), Object.values(objectsToRemove));
                    }
                );
        }
    }

    const processObject = (thatObject, stack) => {
        return new Promise((resolve, reject) => {
            let objectsToAdd = {};
            objectsToAdd[thatObject['objeto360']] = thatObject;

            let objectsToRemove = {};
            const objectSameZ = objectsInScene.filter(object => object.z === thatObject.z);
            objectSameZ.forEach(object => objectsToRemove[object['objeto360']] = object);

            const excludedCategories = (thatObject.categorias_excluidas ?? '').split(',').map(category => parseInt(category));
            const objectsExcludedCategories = objectsInScene.filter(object => excludedCategories.includes(object.id_categorias));

            if (objectsExcludedCategories.length > 0) {
                const objectsExcludedCategoriesIds = objectsExcludedCategories.map(object => object.objeto360);

                if (Object.values(stack.objectsToRemove).every(object => !objectsExcludedCategoriesIds.includes(object.objeto360))) {
                    setConfirmation({
                        ...confirmation,
                        parentObjects: [],
                        newObject: thatObject,
                        objectsToDelete: objectsExcludedCategories,
                        modalIsOpen: true,
                        onConfirmation: () => {
                            onCloseConfirmation();
                            objectsExcludedCategories.forEach(object => objectsToRemove[object['objeto360']] = object);
                            resolve({objectsToAdd, objectsToRemove});
                        },
                        onCloseConfirmation: () => {
                            onCloseConfirmation();
                            reject();
                        }
                    });
                } else {
                    resolve({objectsToAdd, objectsToRemove});
                }
            } else {
                resolve({objectsToAdd, objectsToRemove});
            }
        })
            .then(
                ({objectsToAdd, objectsToRemove}) => {
                    const parentIds = thatObject.objeto360_padre ? thatObject.objeto360_padre.split(',').map(e => parseInt(e)) : [];
                    console.log(parentIds)
                    if (parentIds.length > 0) {
                        const parentObject = objectsInScene.find(object => parentIds.includes(object.objeto360))
                        if (!parentObject) {
                            return initialState.repository.getOptionByObjectIds(state.scene, state.style, state.customer, parentIds)
                                .then(
                                    (parentObjects) => {
                                        return new Promise((resolve, reject) => {
                                            setConfirmation({
                                                ...confirmation,
                                                parentObjects: parentObjects,
                                                newObject: thatObject,
                                                objectsToDelete: [],
                                                modalIsOpen: true,
                                                onCloseConfirmation: () => {
                                                    onCloseConfirmation();
                                                    reject();
                                                },
                                                onConfirmation: (parentObject) => {
                                                    processObject(parentObject, {objectsToAdd, objectsToRemove})
                                                        .then(
                                                            ({objectsToAdd: othersObjectsToAdd, objectsToRemove: othersObjectsToRemove}) => {
                                                                onCloseConfirmation();
                                                                resolve({
                                                                    objectsToAdd: {...objectsToAdd, ...othersObjectsToAdd},
                                                                    objectsToRemove: {...objectsToRemove, ...othersObjectsToRemove}
                                                                });
                                                            }
                                                        );
                                                }
                                            });
                                        })
                                    }
                                )
                        }
                    }
                    return {objectsToAdd, objectsToRemove};
                }
            )
            .catch(() => {
            });
    }

    const updateScene = (objectsToAdd, objectsToRemove) => {

        objectsToRemove.forEach(object => setTimeout(() => setObjectToRemove({...object}), 100))
        objectsToAdd.forEach(object => {
            sessionManager.addSKU(object);
            setTimeout(() => setObjectToAdd({...object}), 100)
        })

        const objectsToRemoveIds = objectsToRemove.map(object => object.objeto360);
        let newObjectsInScene = objectsInScene
            .filter(object => !objectsToRemoveIds.includes(object.objeto360))
            .concat(objectsToAdd);

        setObjectsInScene(newObjectsInScene);
    }

    const setSceneStyle = (selectedScene, selectedStyle) => {
        if ((selectedScene, selectedStyle)) {
            const newState = {
                ...state,
                scene: selectedScene,
                style: selectedStyle
            }
            setState(newState);
        }
    }

    const toogleMenu = () => {
        const krpano = document.querySelector('.krpano-container');
        krpano.classList.toggle('krpano-container-full');

        const menu = document.querySelector('.menu-container');
        menu.classList.toggle('menu-container-close');
    }

    return (
        <AppContext.Provider value={initialState}>
            <div className="confirmation-container">
                <Confirmation state={confirmation} onCloseModal={onCloseConfirmation}/>
            </div>

            {
                state.tour === 'debug' &&
                <div className="scene-selector-container">
                    <SceneSelector initialState={initialState.state} setSceneStyle={setSceneStyle}/>
                </div>
            }
            <div className="container">
                <div className="menu-container">
                    <Menu onLeafOptionClick={onLeafOptionClick} state={state} onOpenMenu={toogleMenu}
                          onCloseMenu={toogleMenu}/>
                </div>

                <div className="krpano-container">
                    <KrpanoView objectToAdd={objectToAdd} objectToRemove={objectToRemove} state={state}/>
                    {
                        (state.tour === 'debug' || state.tour === 'coronadist') &&
                        <div className="toolbar-container">
                            <Toolbar scene={state.scene} style={state.style} customer={state.customer}
                                     onSelectSKU={onLeafOptionClick} objectsInScene={objectsInScene}/>
                        </div>
                    }

                </div>
            </div>
        </AppContext.Provider>
    );
}

export default Corona;
