import './App.css';
import AppContext from './context/AppContext';
import useAppState from "./hooks/useAppState";
import Corona from "./components/Corona";

function App() {

    const queryParams = new URLSearchParams(window.location.search);
    const scene = queryParams.get('escena');
    const style = queryParams.get('estilo');
    const customer = queryParams.get('cliente') ?? '1';
    const tour = queryParams.get('tour') ?? 'corona';

    const initialState = useAppState({scene, style, customer, tour});
    
    return (
        <AppContext.Provider value={initialState}>
            <Corona></Corona>
        </AppContext.Provider>
    );
}

export default App;
