import { useEffect, useState } from 'react'
import useKrpano from 'react-krpano-hooks'

const useKrpanoView = (state) => {
    const {
        containerRef,
        krpanoState: { isLoaded },
        callKrpano,
    } = useKrpano({
        height: '100%',
        width: '100%',
        embeddingParams: {
            html5: "auto",
            passQueryParameters: true,
        }
    });

    return {
        isLoaded,
        containerRef,
        callKrpano,
    }
}

export default useKrpanoView;
