import {Fragment} from "react";
import FormWidthHeight from "./FormWidthHeight";

function FormCategory({category, objects}) {
    return <Fragment>
        {
            objects.length > 0
                ?
                <section className="form-category">
                    <p className="form-category-title">{category.nombre}</p>
                    {
                        objects.map(object => <FormWidthHeight key={object.id} category={category} object={object}/>)
                    }
                </section>
                :
                ''
        }
    </Fragment>;
}

export default FormCategory;
